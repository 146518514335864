import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import { UserRemoveWorkRequestType } from "types/User/UserWorkType";

//types
import { MessageResponseType } from "types/GlobalType";
import { UserAddWorkRequestType } from "types/User/UserWorkType";

export const userWorkApi = createApi({
  reducerPath: "userWorkApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getAllWorks: builder.mutation({
      query: () => ({
        url: "/user/work-experience",
        method: "GET",
      }),
    }),
    addWork: builder.mutation<MessageResponseType, UserAddWorkRequestType>({
      query: (body) => ({
        url: "/user/work-experience",
        method: "POST",
        body,
      }),
    }),
    removeWork: builder.mutation<
      MessageResponseType,
      UserRemoveWorkRequestType
    >({
      query: ({ id }) => ({
        url: `/user/work-experience/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllWorksMutation,
  useAddWorkMutation,
  useRemoveWorkMutation,
} = userWorkApi;
