import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

//types
import { MessageResponseType } from "types/GlobalType";
import {
  UserGetProfileResponseType,
  UserSetMigrationProgramTypeRequestType,
  UserSetProfileRequestType,
  UserGetNotificationResponseType,
} from "types/User/UserType";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getProfile: builder.mutation<UserGetProfileResponseType, null>({
      query: () => ({
        url: "/user/profile",
        method: "GET",
      }),
    }),
    setProfile: builder.mutation<
      MessageResponseType,
      UserSetProfileRequestType
    >({
      query: ({ body }) => ({
        url: "/user/profile",
        method: "PUT",
        body,
      }),
    }),
    getNotifications: builder.mutation<UserGetNotificationResponseType[], null>(
      {
        query: () => ({
          url: "/user/notification",
          method: "GET",
        }),
      }
    ),
    setMigrationProgram: builder.mutation<
      MessageResponseType,
      UserSetMigrationProgramTypeRequestType
    >({
      query: ({ body }) => ({
        url: `/user/set-migration-program`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetProfileMutation,
  useSetProfileMutation,
  useGetNotificationsMutation,
  useSetMigrationProgramMutation,
} = userApi;
