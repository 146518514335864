import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import {
  Notification,
  UserFields,
  UserGetNotificationResponseType,
  UserGetProfileResponseType,
  UserType,
} from "types/User/UserType";

const initialState: UserType & UserFields = {
  id: 0,
  email: "",
  firstName: "",
  lastName: "",
  yearOfBirth: new Date(),
  country: 0,
  city: "",
  gender: 0,
  education: 0,
  occupation: "",
  passedTest: 0,
  englishLevel: 0,
  phoneNumber: "",
  isProfileCompleted: false,
};

const initialStateNotification: Notification[] = [
  {
    notifiableId: 0,
    data: {
      title: "",
      description: "",
    },
    onDashboard: false,
    notificationType: "error",
    readAt: new Date(),
  },
];

const slice = createSlice({
  name: "user",
  initialState: {
    user: initialState,
    notifications: initialStateNotification,
  },
  reducers: {
    setUser: (state, action: PayloadAction<UserGetProfileResponseType>) => {
      state.user = action.payload;
    },
    setNotifications: (
      state,
      action: PayloadAction<UserGetNotificationResponseType[]>
    ) => {
      state.notifications = action.payload;
    },
  },
});

export const userActions = slice.actions;

export default slice.reducer;
