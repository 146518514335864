import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import { UserRemoveSkillRequestType } from "types/User/UserSkillType";

//types
import { MessageResponseType } from "types/GlobalType";
import { UserAddSkillRequestType } from "types/User/UserSkillType";

export const userSkillApi = createApi({
  reducerPath: "userSkillApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getAllSkills: builder.mutation({
      query: () => ({
        url: "/user/skill",
        method: "GET",
      }),
    }),
    addSkill: builder.mutation<MessageResponseType, UserAddSkillRequestType>({
      query: (body) => ({
        url: "/user/skill",
        method: "POST",
        body,
      }),
    }),
    removeSkill: builder.mutation<
      MessageResponseType,
      UserRemoveSkillRequestType
    >({
      query: ({ id }) => ({
        url: `/user/skill/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllSkillsMutation,
  useAddSkillMutation,
  useRemoveSkillMutation,
} = userSkillApi;
