import { paymentApi } from "./api/payment.api";
import { userMilitaryServiceApi } from "./api/user/employment/militaryService.api";
import { userDocsApi } from "./api/user/user.doc.api";
import { userSkillApi } from "./api/user/user.skill.api";
import { userLanguageApi } from "./api/user/user.language.api";
import { userEducationApi } from "./api/user/user.education.api";
import { userWorkApi } from "./api/user/user.work.api";
import { userMigrationProgramApi } from "./api/user/user.migrationProgram.api";
import { userQuestionnaireApi } from "./api/user/user.questionnaire.api";
import { userApi } from "./api/user/user.api";
import { authApi } from "./api/auth.api";
import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import userSlice from "./slices/user/user.slice";
import { selectApi } from "./api/selects.api";
import userWorkSlice from "./slices/user/user.work.slice";
import userEducationSlice from "./slices/user/user.education.slice";
import userLanguageSlice from "./slices/user/user.language.slice";
import userSkillSlice from "./slices/user/user.skill.slice";
import userDocSlice from "./slices/user/user.doc.slice";
import userMilitaryServiceSlice from "./slices/user/employment/militaryService.slice";

const apiReducers = {
  [authApi.reducerPath]: authApi.reducer,
  [selectApi.reducerPath]: selectApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [userWorkApi.reducerPath]: userWorkApi.reducer,
  [userEducationApi.reducerPath]: userEducationApi.reducer,
  [userLanguageApi.reducerPath]: userLanguageApi.reducer,
  [userSkillApi.reducerPath]: userSkillApi.reducer,
  [userDocsApi.reducerPath]: userDocsApi.reducer,
  [userMilitaryServiceApi.reducerPath]: userMilitaryServiceApi.reducer,
  [userQuestionnaireApi.reducerPath]: userQuestionnaireApi.reducer,
  [userMigrationProgramApi.reducerPath]: userMigrationProgramApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
};

const sliceReducers = {
  auth: authSlice,
  user: userSlice,
  userWork: userWorkSlice,
  userEducation: userEducationSlice,
  userLanguage: userLanguageSlice,
  userSkill: userSkillSlice,
  userDoc: userDocSlice,
  userMilitaryService: userMilitaryServiceSlice,
};

export const reducers = combineReducers({
  ...apiReducers,
  ...sliceReducers,
});
