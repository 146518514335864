import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import {
  UserRemoveLanguageRequestType,
  UserUpdateLanguageRequestType,
} from "types/User/UserLanguageType";

//types
import { MessageResponseType } from "types/GlobalType";
import { UserAddLanguageRequestType } from "types/User/UserLanguageType";

export const userLanguageApi = createApi({
  reducerPath: "userLanguageApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getAllLanguages: builder.mutation({
      query: () => ({
        url: "/user/language",
        method: "GET",
      }),
    }),
    addLanguage: builder.mutation<
      MessageResponseType,
      UserAddLanguageRequestType
    >({
      query: (body) => ({
        url: "/user/language",
        method: "POST",
        body,
      }),
    }),
    updateLanguage: builder.mutation<
      MessageResponseType,
      UserUpdateLanguageRequestType
    >({
      query: ({ id, body }) => ({
        url: `/user/language/${id}`,
        method: "PUT",
        body,
      }),
    }),
    removeLanguage: builder.mutation<
      MessageResponseType,
      UserRemoveLanguageRequestType
    >({
      query: ({ id }) => ({
        url: `/user/language/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllLanguagesMutation,
  useAddLanguageMutation,
  useUpdateLanguageMutation,
  useRemoveLanguageMutation,
} = userLanguageApi;
