import React, { Suspense } from "react";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { Helmet } from "react-helmet";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { isExpired } from "utils/isExpired";
import Redirect from "./pages/Redirect";
//Pages
import { SignIn } from "./pages/Auth/SignIn/Loadable";
import { SignUp } from "./pages/Auth/SignUp/Loadable";
import { Dashboard } from "./pages/User/Dashboard/Loadable";
import { Inventory } from "./pages/User/Inventory/Loadable";
import { Payment } from "./pages/User/Payment/Loadable";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { SocialCallback } from "./pages/Auth/SocialCallback/Loadable";
import { Resume } from "./pages/User/Inventory/pages/Resume/Loadable";
import { Employment } from "./pages/User/Inventory/pages/Employment/Loadable";
import { Success } from "./pages/User/Payment/pages/Success/Loadable";
import { Cancel } from "./pages/User/Payment/pages/Cancel/Loadable";

const App = () => {
  const { expiresAt, token } = useTypedSelector((state) => state.auth);
  const { logOut } = useActions();
  if (isExpired(expiresAt) && !token) {
    logOut();
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title>GIC Canada</title>
        <meta name="description" content="GIC Canada Immigration Company" />
      </Helmet>
      <Suspense
        fallback={
          <div className="flex h-screen justify-center items-center">
            Loading...
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Redirect />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/social-callback" element={<SocialCallback />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="/inventory"
            element={<PrivateRoute component={Inventory} />}
          />
          <Route
            path="/inventory/resume/:id"
            element={<PrivateRoute component={Resume} />}
          />
          <Route
            path="/inventory/employment/:id"
            element={<PrivateRoute component={Employment} />}
          />
          <Route
            path="/payment"
            element={<PrivateRoute component={Payment} />}
          />
          <Route
            path="/payment/success"
            element={<PrivateRoute component={Success} />}
          />
          <Route
            path="/payment/cancel"
            element={<PrivateRoute component={Cancel} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export { App };
