import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import { UserRemoveEducationRequestType } from "types/User/UserEducationType";

//types
import { MessageResponseType } from "types/GlobalType";
import { UserAddEducationRequestType } from "types/User/UserEducationType";

export const userMilitaryServiceApi = createApi({
  reducerPath: "userMilitaryServiceApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getMilitaryService: builder.mutation({
      query: () => ({
        url: "/user/military-service",
        method: "GET",
      }),
    }),
    saveMilitaryService: builder.mutation<
      MessageResponseType,
      UserAddEducationRequestType
    >({
      query: (body) => ({
        url: "/user/military-service",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetMilitaryServiceMutation, useSaveMilitaryServiceMutation } =
  userMilitaryServiceApi;
