import { createApi } from "@reduxjs/toolkit/query/react";
import { FieldValues } from "react-hook-form";
import { baseQuery } from "store/baseQuery";

//types
import {
  AuthType,
  ProviderCallbackType,
  SignInRequestType,
  SignUpRequestType,
  SocialProvidersType,
} from "types/AuthType";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthType, SignInRequestType | FieldValues>({
      query: (credentials) => ({
        url: "/user/login",
        method: "POST",
        body: credentials,
      }),
    }),

    signUp: builder.mutation<AuthType, SignUpRequestType | FieldValues>({
      query: (credentials) => ({
        url: "/user/register",
        method: "POST",
        body: credentials,
      }),
    }),

    getProviders: builder.query<SocialProvidersType, null>({
      query: () => "/user/login/social-providers",
    }),

    sendProviderCallback: builder.mutation<AuthType, ProviderCallbackType>({
      query: (data) => ({
        url: `/user/login/${data.provider}/callback`,
        params: {
          code: data.code,
        },
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useGetProvidersQuery,
  useSendProviderCallbackMutation,
} = authApi;
