import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import { UserGetAllDocsResponseType } from "types/User/UserDocType";

const initialState: UserGetAllDocsResponseType[] = [
  {
    id: 0,
    document: "",
    type: "docs",
    error: "",
  },
];

const slice = createSlice({
  name: "userDoc",
  initialState: {
    docs: initialState,
  },
  reducers: {
    setDocs: (state, action: PayloadAction<UserGetAllDocsResponseType[]>) => {
      state.docs = action.payload;
    },
  },
});

export const userDocActions = slice.actions;

export default slice.reducer;
