import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "hooks/useTypedSelector";

const Redirect = () => {
  const navigate = useNavigate();
  const { isAuth } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    if (isAuth) {
      navigate("/dashboard");
      return;
    }
    navigate("/signin");
  }, []);

  return <div></div>;
};

export default Redirect;
