import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import { UserRemoveEducationRequestType } from "types/User/UserEducationType";

//types
import { MessageResponseType } from "types/GlobalType";
import { UserAddEducationRequestType } from "types/User/UserEducationType";

export const userEducationApi = createApi({
  reducerPath: "userEducationApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getAllEducations: builder.mutation({
      query: () => ({
        url: "/user/education",
        method: "GET",
      }),
    }),
    addEducation: builder.mutation<
      MessageResponseType,
      UserAddEducationRequestType
    >({
      query: (body) => ({
        url: "/user/education",
        method: "POST",
        body,
      }),
    }),
    removeEducation: builder.mutation<
      MessageResponseType,
      UserRemoveEducationRequestType
    >({
      query: ({ id }) => ({
        url: `/user/education/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllEducationsMutation,
  useAddEducationMutation,
  useRemoveEducationMutation,
} = userEducationApi;
