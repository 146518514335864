import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import { UserGetAllMilitaryServiceResponseType } from "types/User/Employment/MilitaryServiceType";

const initialState: UserGetAllMilitaryServiceResponseType[] = [
  {
    id: 0,
    itServedInMilitary: false,
    country: "",
    city: "",
    from: new Date(),
    to: new Date(),
    familyMemberType: "",
    error: "",
  },
];

const slice = createSlice({
  name: "userMilitaryService",
  initialState: {
    militaryService: initialState,
  },
  reducers: {
    setMilitaryService: (
      state,
      action: PayloadAction<UserGetAllMilitaryServiceResponseType[]>
    ) => {
      state.militaryService = action.payload;
    },
  },
});

export const userMilitaryServiceActions = slice.actions;

export default slice.reducer;
