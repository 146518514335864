import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import { authActions } from "store/slices/auth.slice";
import { userActions } from "../store/slices/user/user.slice";
import { userLanguageActions } from "./../store/slices/user/user.language.slice";
import { userWorkActions } from "store/slices/user/user.work.slice";
import { userEducationActions } from "store/slices/user/user.education.slice";
import { userSkillActions } from "store/slices/user/user.skill.slice";
import { userDocActions } from "store/slices/user/user.doc.slice";
import { userMilitaryServiceActions } from "store/slices/user/employment/militaryService.slice";

const allActions = {
  ...authActions,
  ...userActions,
  ...userWorkActions,
  ...userEducationActions,
  ...userLanguageActions,
  ...userSkillActions,
  ...userDocActions,
  ...userMilitaryServiceActions,
};

export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(allActions, dispatch);
};
