import React, { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

//hooks
import { useTypedSelector } from "hooks/useTypedSelector";

type PrivateRouteType = {
  component: React.ComponentType;
  path?: string;
};

export const PrivateRoute: FunctionComponent<PrivateRouteType> = ({
  component: RouteComponent,
}) => {
  const { isAuth } = useTypedSelector((state) => state.auth);

  if (isAuth) {
    return <RouteComponent />;
  }

  return <Navigate to="/signin" />;
};
