import { userWorkApi } from "./api/user/user.work.api";
import { userQuestionnaireApi } from "./api/user/user.questionnaire.api";
import { authApi } from "./api/auth.api";
import { userApi } from "./api/user/user.api";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { reducers } from "./reducers";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { selectApi } from "./api/selects.api";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      authApi.middleware,
      userQuestionnaireApi.middleware,
      selectApi.middleware,
      userWorkApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
