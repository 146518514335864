import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import { PaymentType } from "types/PaymentType";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: (builder) => ({
    getAllPayments: builder.query<PaymentType[], null>({
      query: () => "/payment",
    }),
    getStripeUrl: builder.query({
      query: (id) => `/payment/callback/${id}`,
    }),
  }),
});

export const { useGetAllPaymentsQuery, useGetStripeUrlQuery } = paymentApi;
