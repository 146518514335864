import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { RootState } from "store";

export const baseQuery = fetchBaseQuery({
  baseUrl: "https://backend.gic-canada.assisthero.online/api/v1",
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept", "application/json");
    const token = (getState() as RootState).auth.token;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
