import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

export const selectApi = createApi({
  reducerPath: "selectApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getSelects: builder.query({
      query: () => "/select-in-json",
    }),
  }),
});

export const { useGetSelectsQuery } = selectApi;
