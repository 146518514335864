import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

import { UserRemoveDocRequestType } from "types/User/UserDocType";

//types
import { MessageResponseType } from "types/GlobalType";

export const userDocsApi = createApi({
  reducerPath: "userDocsApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getAllDocs: builder.mutation({
      query: () => ({
        url: "/user/document",
        method: "GET",
      }),
    }),
    addDoc: builder.mutation<MessageResponseType, FormData>({
      query: (body) => ({
        url: "/user/document",
        method: "POST",
        body,
      }),
    }),
    removeDoc: builder.mutation<MessageResponseType, UserRemoveDocRequestType>({
      query: ({ id }) => ({
        url: `/user/document/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllDocsMutation,
  useAddDocMutation,
  useRemoveDocMutation,
} = userDocsApi;
