import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

//types
import { MigrationProgramType } from "types/MigrationProgramType";

export const userMigrationProgramApi = createApi({
  reducerPath: "userMigrationProgramApi",
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: (builder) => ({
    getMigrationPrograms: builder.query<MigrationProgramType[], null>({
      query: () => "/user/migration-program",
    }),
  }),
});

export const { useGetMigrationProgramsQuery } = userMigrationProgramApi;
