import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "store/baseQuery";

//types
import {
  QuestionnaireType,
  UpdateQuestionnaireRequestType,
  UpdateQuestionnaireResponseType,
} from "types/QuestionnaireType";

export const userQuestionnaireApi = createApi({
  reducerPath: "userQuestionnaireApi",
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: (builder) => ({
    getUserQuestionnaires: builder.query<QuestionnaireType[], null>({
      query: () => "/user/questionnaire",
    }),
    updateQuestionnaireStatusById: builder.mutation<
      UpdateQuestionnaireResponseType,
      UpdateQuestionnaireRequestType
    >({
      query: ({ id }) => ({
        url: `/user/update-questionnaire-status?questionnaire=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserQuestionnairesQuery,
  useUpdateQuestionnaireStatusByIdMutation,
} = userQuestionnaireApi;
