import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import { UserGetAllWorksResponseType } from "types/User/UserWorkType";

const initialState: UserGetAllWorksResponseType[] = [
  {
    id: 0,
    companyName: "",
    location: "",
    from: new Date(),
    to: new Date(),
    position: "",
    responsibilities: "",
    error: "",
  },
];

const slice = createSlice({
  name: "userWork",
  initialState: {
    works: initialState,
  },
  reducers: {
    setWorks: (state, action: PayloadAction<UserGetAllWorksResponseType[]>) => {
      state.works = action.payload;
    },
  },
});

export const userWorkActions = slice.actions;

export default slice.reducer;
