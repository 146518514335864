import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import { UserGetAllSkillsResponseType } from "types/User/UserSkillType";

const initialState: UserGetAllSkillsResponseType[] = [
  {
    id: 0,
    name: "",
    error: "",
  },
];

const slice = createSlice({
  name: "userSkill",
  initialState: {
    skills: initialState,
  },
  reducers: {
    setSkills: (
      state,
      action: PayloadAction<UserGetAllSkillsResponseType[]>
    ) => {
      state.skills = action.payload;
    },
  },
});

export const userSkillActions = slice.actions;

export default slice.reducer;
