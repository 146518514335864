import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//types
import { UserGetAllEducationsResponseType } from "types/User/UserEducationType";

const initialState: UserGetAllEducationsResponseType[] = [
  {
    id: 0,
    credentialType: 1,
    from: new Date(),
    subject: "",
    nameOfInstitution: "",
    location: "",
    nameOfCredential: "",
    error: "",
  },
];

const slice = createSlice({
  name: "userEducation",
  initialState: {
    educations: initialState,
  },
  reducers: {
    setEducations: (
      state,
      action: PayloadAction<UserGetAllEducationsResponseType[]>
    ) => {
      state.educations = action.payload;
    },
  },
});

export const userEducationActions = slice.actions;

export default slice.reducer;
